<template>
  <div>
    <v-img
      :src="require('@/assets/QRCodeFR.png')"
      :width="200"
      :style="localLang == 'fr' ? 'position: absolute; top: 10px; right: 100px' : 'position: absolute; top: 10px; left: 100px'"
      class="hidden-sm-and-down hidden-md-and-down white--text align-end"
      alt=""
    />
    <v-row>
      <v-col style="" class="mx-5 text-center" cols="12" lg="6" md="12" sm="12">
        <h1 style="font-size: 35px; color: #666">{{ $t("information") }}</h1>
        <div class="h-decor"></div>
        <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
          <!-- <v-timeline-item color="red lighten-2" icon="mdi-domain" fill-dot>
            <v-card color="red lighten-2" dark>
              <v-card-title class="title">{{
                getInfo ? getInfo.companyName : ""
              }}</v-card-title>
            </v-card>
          </v-timeline-item> -->
          <v-timeline-item color="indigo" icon="mdi-phone" fill-dot>
            <v-card color="indigo" dark>
              <v-card-title class="title "><span class="mx-auto" style="font-size: 25px">{{
                getInfo ? getInfo.phone.replace(/ /g, "") : ""
              }}</span></v-card-title>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="purple darken-1" icon="mdi-fax" fill-dot>
            <v-card color="purple darken-1" dark>
              <v-card-title class="title "><span class="mx-auto"  style="font-size: 25px">{{
                getInfo ? getInfo.fax.replace(/ /g, "") : ""
              }}</span></v-card-title>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="green lighten-1"
            icon="mdi-map-marker"
            fill-dot
          >
            <v-card color="green lighten-1" dark>
              <v-card-title class="title"><span class="mx-auto" >{{
                getInfo ? getInfo.adress : ""
              }}</span></v-card-title>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="blue lighten-2" icon="mdi-email" fill-dot>
            <v-card color="blue lighten-2" dark>
              <v-card-title class="title"><span class="mx-auto"  style="" >{{
                getInfo ? getInfo.email : ""
              }}</span></v-card-title>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-col class="ml-10 text-center" cols="12" md="12" lg="5" sm="12" xs="12">
        <h1 style="font-size: 35px; color: #666">
          {{ $t("accedeResult") + " :" }}
        </h1>
        <div class="h-decor"></div>
        <v-card
          style="border-top: 3px solid #3f51b5; "
          :loading="isLoading"
          class="mx-auto mt-12"
          max-width="500"
         max-height="395"
          outlined
        > 
                <v-card   max-height="395" flat>
                     <v-card-title primary-title>
                          <span style="color:#777">{{ $t('titleAnalyses') }}</span>
                          
                     </v-card-title>
                  <v-card-text>
                    <v-row class="py-0 my-0">
                      <v-col cols="12" md="12" class="mt-2">
                        <v-text-field
                          clearable
                          outlined
                          hide-details
                          v-model="form.username"
                          dense
                          :label="getInfo ? getInfo.labelUser : ''"
                          required
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" class="mt-3">
                        <v-text-field
                          clearable
                          outlined
                          hide-details
                          v-model="form.password"
                          dense
                          :label="getInfo ? getInfo.lablePass : ''"
                          required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card> 
            <v-btn
              class="mt-2 mx-auto"
              outlined
              :disabled="isLoading"
              @click="getResult"
              dark
              color="#3F51B5"
            >
              {{ $t("recuperAnalys") }}
            </v-btn>
            <img width="100%" class="" src="../assets/footer.png"  alt="">

 
        </v-card>
      </v-col>
      <v-col class="text-center my-12" cols="12" height="100">
        <h1 style="font-size: 40px; color: #666">{{ $t("locationOnCard") }}</h1>
        <div class="h-decor"></div>
        <GmapMap
          :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            disableDefaultUi: true,
          }"
          ref="mapRef"
          v-bind:center="{
            lat: filterFloat(getInfo ? getInfo.lat : 0),
            lng: filterFloat(getInfo ? getInfo.lang : 0),
          }"
          :zoom="16"
          map-type-id="terrain"
          style="width: 100%; margin-top: 40px; height: 400px"
        >
          <GmapMarker
            :position="{
              lat: filterFloat(getInfo ? getInfo.lat : 0),
              lng: filterFloat(getInfo ? getInfo.lang : 0),
            }"
            :clickable="true"
            :draggable="true"
          />
        </GmapMap>
      </v-col>
    </v-row>

    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="80"></v-progress-circular>
    </v-overlay>

    <v-dialog
      style="background-color: transparent !important"
      v-model="dialog"
      max-width="650"
    >
      <v-card>
        <v-card-title class="headline">{{ $t("voResult") }}</v-card-title>
        <v-card-text>
          <iframe
            :src="((getInfo ? getInfo.n_labo : 0) + '/result/' + file) | URL1"
            id="ifram1"
            width="100%"
            height="700"
          ></iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">{{
            $t("close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div v-if="!isLoading">
          {{ getInfo }}
     </div>
     <div v-else>
          is loading...
     </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      file: "",
      form: {
        n_labo: "",
        username: "",
        password: "",
      },
      bgSide:
        "background:url(" +
        require("@/assets/bg-bottom-right.png") +
        ")  no-repeat fixed right , url(" +
        require("@/assets/bg-bottom-left2.png") +
        ")  no-repeat fixed left ;",
    };
  },

  computed: {
    getInfo() {
      return this.$store.state.info[0];
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    localLang() {
      return this.$i18n.locale;
    },
  },

  methods: {
    getResult() {
      this.form.n_labo = this.getInfo.n_labo;
      this.$store
        .dispatch("getResult", JSON.stringify(this.form))
        .then((data) => {
          this.file = data;
          this.dialog = true;
        })
        .catch((err) => {
          this.dialog = false;
          console.log("error => ", err);
        });
    },
    filterFloat(value) {
      if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value))
        return Number(value);
      return NaN;
    },
  },

  created() {
    this.$store.dispatch("getInfo");
  },
};
</script>

<style>
.v-sheet.v-card {
    border-radius: 20px !important;
}
.v-timeline-item {
  padding-bottom: 15px !important;
}
</style>
